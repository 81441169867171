<template>
  <b-card title="Danh sách sản phẩm">
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block"
            placeholder="Tên sản phẩm"
            type="text"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <span v-if="props.column.field === 'name'" class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <router-link :to="{name: 'manage-product-edit', params: {id: props.row.id}}">
            <span class="text-nowrap">{{ props.row.name }}</span>
          </router-link>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'nksx'">
          <router-link :to="{name: 'manage-nksx-edit', params: {id: JSON.parse(props.row.nksx).id}}">
            {{ JSON.parse(props.row.nksx).title }}
          </router-link>
        </span>

        <span v-else-if="props.column.field === 'price'">
          {{ props.row.is_contact_price === '1' ? 'Liên hệ' : props.row.price }}
        </span>

        <span v-else-if="props.column.field === 'unit'">
          {{ props.row.is_contact_price === '1' ? '' : props.row.unit }}
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'category_id'">
          {{ props.row.category.name }}
        </span>

        <!-- Column: created_at -->
        <span v-else-if="props.column.field === 'created_at'">
          {{ new Date(props.row.created_at).toLocaleString() }}
        </span>

        <!-- Column: created_at -->
        <span v-else-if="props.column.field === 'is_feature_product'">
          {{ props.row.is_feature_product == 1 ? 'Có' : 'Không' }}
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mr-2"
            type="submit"
            variant="danger"
            @click="deleteConfirmation(props.row, props.index)"
          >Xóa</b-button>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {BAvatar, BCard, BFormGroup, BFormInput, BButton} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store/index'
import Ripple from "vue-ripple-directive";
import ToastificationContent from "../../../@core/components/toastification/ToastificationContent";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    VueGoodTable,
    BAvatar,
    BButton,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      categories: [],
      pageLength: 100,
      dir: false,
      columns: [
        {
          label: 'Tên',
          field: 'name',
        },
        {
          label: 'Giá',
          field: 'price',
        },
        {
          label: 'Đơn vị',
          field: 'unit',
        },
        {
          label: 'Nhật ký sản xuất',
          field: 'nksx',
        },
        {
          label: 'Danh mục',
          field: 'category_id',
        },
        // {
        //   label: 'Code',
        //   field: 'code',
        // },
        // {
        //   label: 'Ngày tạo',
        //   field: 'created_at',
        // },
        {
          label: 'Trang chủ',
          field: 'is_feature_product',
        },
        {
          label: 'Cập nhật',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.$http.get('/products')
      .then(res => {
        this.rows = res.data.data
      })
  },
  methods: {
    deleteProduct(product, idx) {
      this.$http.delete(`/products/${product.id}`)
        .then(response => {
          console.log(response.data)
          this.deleteNksx(JSON.parse(product.nksx).id)
          this.rows.splice(idx, 1)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thông báo',
              icon: 'BellIcon',
              text: 'Cập nhật thành công👋',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thông báo',
              icon: 'BellIcon',
              text: 'Đã xảy ra lỗi. Vui lòng thử lại sau!',
              variant: 'danger',
            },
          })
        })
    },
    deleteNksx(id) {
      this.$http.delete(`/posts/${id}`)
          .then(response => {
            console.log(response.data)
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Thông báo',
                icon: 'BellIcon',
                text: 'Đã xảy ra lỗi. Vui lòng thử lại sau!',
                variant: 'danger',
              },
            })
          })
    },
    deleteConfirmation(product, idx){
      this.$bvModal.msgBoxConfirm('Hành động này không thể khôi phục. Vẫn xóa?', {
        title: 'Xác nhận muốn xóa dữ liệu',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Xóa',
        cancelTitle: 'Hủy',
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: true
      })
        .then(value => {
          if (value) {
            this.deleteProduct(product, idx)
          }
        })
        .catch(err => {
          console.log(err, product)
        })
    }
  }
}
</script>
